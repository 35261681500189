import './accueil.css';
import logoMinefop from '../../../assets/images/logo_minefop.jpg';
import logo from '../../../assets/images/logo.svg';
import { useNavigate } from 'react-router-dom';

function Accueil() {
  let navigate = useNavigate();
  function navigateTo(route) {
    navigate(route);
  }
  return (
    <div className="accueil-backgroung-image">
      <div className="accueil-backgroung-cover-image">
        <div className="accueil">
          <div className="accueil-background-cover">
            <section className="accueil-section-1">
              <p className="accueil-section-1-p-1">
                Ministère de l'Emploi et de La Formation Profesionnelle
              </p>
              <div style={{ textAlign: 'center' }}>
                <img
                  className="accueil-section-1-logo-minefop"
                  src={logoMinefop}
                  alt=""
                />
              </div>
              <h5>
                CENTRE DE FORMATION PROFESSIONNELLE ET DE LA PROMOTION DE
                L'EMPLOI NJA'MOUN DE KOUTABA
              </h5>
              <h1>(CEFPEN)</h1>
              <div style={{ textAlign: 'center' }}>
                <img
                  className="accueil-section-1-logo-njamoun"
                  src={logo}
                  alt="Logo"
                />
              </div>
              <p className="accueil-section-1-p-2">
                <span style={{ color: '#593da1' }}>Pour le génie </span>
                <span style={{ color: '#fa7136' }}>Créatif des jeunes</span>
              </p>
            </section>
            <section className="accueil-section-2">
              <h4>NOS FILIERES</h4>
              <ul>
                <li
                  onClick={() => {
                    navigateTo('/pastoral-industry');
                  }}
                >
                  Agri-business
                </li>
                <li
                  onClick={() => {
                    navigateTo('/building-industry');
                  }}
                >
                  Industrie du Bâtiment et Habitat Durable
                </li>
                <li
                  onClick={() => {
                    navigateTo('/creative-industry');
                  }}
                >
                  Industrie Créative, Artisanale & Tourisme
                </li>
                <li
                  onClick={() => {
                    navigateTo('/technology-industry');
                  }}
                >
                  Technologie du Future & Innovation
                </li>
              </ul>
              <p className="accueil-section-2-p-1">
                PRE-INSCRIPTION: 01 Juillet 06 SEPTEMBRE 2024
              </p>
              <p className="accueil-section-2-p-2">
                RENTRÉE ACADEMIQUE: OCTOBRE 2024
              </p>
              <p
                className="link-to-calendar"
                onClick={() => {
                  navigateTo('/calendar');
                }}
              >
                Voir le calendrier académique
              </p>
            </section>
            <section className="accueil-section-4">
              <h4
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigateTo('/unite-incubation');
                }}
              >
                UNITE D'INCUBATION ET DE L'INNOVATION ENTREPRENEURIALE
              </h4>
              <p>
                L'unité d'incubation et de l'innovation entrepreneuriale (U2iE)
                est une direction créée au sein du centre de formation
                professionnelle et de la promotion de l'emploi Nja'moun de
                Koutaba (CFPPEK) pour contribuer de manière soutenable à
                l'éclosion du génie créatif de la jeunesse et à les traduire en
                initiatives entrepreneuriales innovantes, pouvant contribuer au
                développement social et économique durable. Il s'agit d'un cadre
                de création et de maturation d'entreprises viables, un lieu de
                renforcement des capacités entrepreneuriales avec un accent sur
                l'autonomisation socio-économique des jeunes et femmes visant la
                réduction des inégalités.
              </p>
              <h3 style={{ textAlign: 'center' }}>Nos principales missions</h3>
              <ul>
                <li>
                  Orienter et guider les apprenants dans le choix de leurs
                  métiers ou initiatives entrepreneuriale;
                </li>
                <li>
                  Renforcer les capacités des apprenants en compétences
                  entrepreneuriales, compétences de vie, leadership, au montage
                  de projets (élaboration des plans d'affaires compétitifs
                  individuels et collectifs, montage financier);
                </li>
                <li>
                  Soutenir l'organisation des stages pratiques, l'encadrement et
                  la recherche des opportunités de financement pour les
                  initiatives entrepreneuriales au profit des apprenants et
                  particuliers (retraités et autres);
                </li>
                <li>
                  Accueillir et orienter les fonctionnaires et retraités qui
                  veulent se reconvertir ou entrependre une activité autre.
                </li>
              </ul>
            </section>
            <section className="accueil-section-3">
              <h4>NOS ATOUTS</h4>
              <ol>
                <li>Un apprenat = un projet = un emploi</li>
                <li>
                  Le Centre offre un système d'apprentissage basé sur les
                  compétances, sur le savoir-faire au delà du diplôme.
                </li>
                <li>
                  Le Centre offre dès l'inscription : tenus, fournitures et
                  intrans pour la formation. En fin de formation, une assistance
                  en kit ou accès au crédit d'installation est offert aux
                  apprenants.
                </li>
                <li>
                  La formation se déroule en alternance : théorie et pratique en
                  entreprise tout au long du parcours.
                </li>
                <li>
                  Les formations sont ouvertes à tous et à toutes quelque soit
                  le niveau scolaire. Même les non-scolarisés sont admis; chaque
                  jeune doit avoir une chance pour éclore son génie.
                </li>
                <li>
                  Le Centre offre une assistance aux fontionnaires, aux
                  personnes qui veulent reconvertir leurs activités ou avoir une
                  activité addionnelle pour augmenter leurs revenus ou de
                  pouvoir développer une activités rentable.
                </li>
                <li>
                  Le Centre offre une opportunité de soutein à la création
                  d'entreprise.
                </li>
              </ol>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Accueil;
