function Nguon() {
  return (
    <div className="recrutement-formateurs">
      <section className="recrutement-formateurs-section-1">
        <div></div>
      </section>
      <div style={{ backgroundColor: '#FFF' }}>
        <div className="recrutement-formateurs-background-image">
          <div className="recrutement-formateurs-background-cover-image">
            <div className="recrutement-formateurs-container">
              <h1>CENTRE NJA'MOUN ET FONDATION DU NGUON</h1>
              <div style={{ height: 10 }}></div>
              <p className="actuality-item-p-2">
                Signature d'une convention entre le Centre Nja'Moun et la
                Fondation Nguon ce Vendredi 28 juin 2024. L'objectif principal
                est de saisir l'opportunité de la célébration du NGUON 2024 pour
                promouvoir l'entreprenariat innovant des jeunes et des femmes
                comme vecteur de la vision royale de développement.
              </p>
              <h2>OBJECTIF SPECIFIQUES</h2>
              <ul>
                <li>
                  Mettre en place un stand pour servir comme « Unité Mobile
                  d'Incubation et d'Innovation Entrepreneuriale » sur le site du
                  village du NGUON pour la période du 29 novembre au 8 décembre
                  2024.
                </li>
                <li>
                  Mettre en place un guichet unique pour soutenir la création
                  d'entreprise sur site.
                </li>
                <li>
                  Fournir des formations gratuites aux jeunes et aux femmes sur
                  les compétences entrepreneuriales, la fiscalité, la
                  co-production connectée, le marketing digital, montage des
                  plans d'affaires compétitifs, l'éducation financière,
                  recherche de financement, leadership et les compétences de vie
                  pour un bon entrepreneur durant la semaine du NGUON.
                </li>
                <li>
                  Organiser une table ronde publique sur le thème :
                  L'entreprenariat innovant des jeunes et des femmes comme
                  vecteur de la vision de Sa Majesté le Sultan{' '}
                  <strong>Mouhammad Nabil MFORIFUM MBOMBO NJOYA</strong>, 20ème
                  Roi de la dynastie Bamoun, qui projette le{' '}
                  <strong>
                    « ROYAUME BAMOUN A L'HORIZON 2050 DANS UN CAMEROUN EMERGENT
                    : BIEN-ETRE ET COHESION SOCIALE »
                  </strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nguon;
