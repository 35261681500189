function Stagiaires() {
  return (
    <div className="recrutement-formateurs">
      <section className="recrutement-formateurs-section-1">
        <div></div>
      </section>
      <div style={{ backgroundColor: '#FFF' }}>
        <div className="recrutement-formateurs-background-image">
          <div className="recrutement-formateurs-background-cover-image">
            <div className="recrutement-formateurs-container">
              <h1>
                RECRUTEMENT ET FORMATION DES STAGIAIRES POUR LA COMPAGNE DE
                SENSIBILISATION PAR LE CENTRE NJA'MOUN
              </h1>
              <h2>CONTEXTE</h2>
              <p>
                En prélude à la campagne de sensibilisation de la population sur
                les services offerts par le Centre de Formaion Professionnelle
                et de Promotion de l'Emploi Nja'moun, les stagiaires ont été
                recrutés. ces derniers ont suivi une séance de formation ce
                lundi 12 août 2024. Il été question de les former sur les
                stratégies de sensibilisation, et les rappeler les différentes
                tâches qu'ils auront à faire sur le terrain entre autre: la
                sensibilisation, les pré-inscriptions des apprenants... Le
                Directeur des Etudes de la Recherche et de l'Innovation du
                CEFPEN a insisté durant cette formation sur les valeurs que ces
                stagiaire doivent promouvoir car ces dernier servira de relaie
                entre le Centre Nja'moun et la population durant le un mois que
                durera leur stage.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stagiaires;
