import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Contact from './views/pages/contact/Contact';
import Header from './views/components/header/Header';
import Footer from './views/components/Footer/Footer';
import Actuality from './views/pages/actuality/Actuality';
import Album from './views/pages/album/Album';
import AboutUs from './views/pages/aboutUs/About_us';
import VisionMission from './views/pages/visionMission/Vision_mission';
import Goals from './views/pages/goals/Goals';
import BuildingInsdustry from './views/pages/buildingIndustry/building_industry';
import CreativeInsdustry from './views/pages/creativeIndustry/creative_industry';
import TechnologyInsdustry from './views/pages/technologyIndustry/technologyIndustry';
import PastoralIndustry from './views/pages/postoralIndustry/pastoral_industry';
import PreRegistration from './views/pages/pre-registration/preRegistration';
import CandidateurApprenant from './views/pages/candidatureApprenant/candidature_apprenant';
import RecrutementFormateurs from './views/pages/recrutementFormateurs/recrutement_formateurs';
import Accueil from './views/pages/accueil/accueil';
import UniteIncubation from './views/pages/uniteIncubation/unite_incubation';
import AdmissionUniteIncubation from './views/pages/admissionUniteIncubation/admission_unite_incubation';
import Nguon from './views/pages/nguon/nguon';
import Stagiaires from './views/pages/stagiaires/stagiaires';
import Calendar from './views/pages/calendar/calendar';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/vision-mission" element={<VisionMission />} />
        <Route path="/goals" element={<Goals />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/building-industry" element={<BuildingInsdustry />} />
        <Route path="/creative-industry" element={<CreativeInsdustry />} />
        <Route path="/pastoral-industry" element={<PastoralIndustry />} />
        <Route path="/technology-industry" element={<TechnologyInsdustry />} />
        <Route path="/pre-registration" element={<PreRegistration />} />
        <Route path="/actuality" element={<Actuality />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/album" element={<Album />} />
        <Route
          path="/candidature-apprenant"
          element={<CandidateurApprenant />}
        />
        <Route
          path="/recrutement-formateurs"
          element={<RecrutementFormateurs />}
        />
        <Route path="/unite-incubation" element={<UniteIncubation />} />
        <Route
          path="/admission-unite-incubation"
          element={<AdmissionUniteIncubation />}
        />
        <Route path="/nguon" element={<Nguon />} />
        <Route path="/stagiaires" element={<Stagiaires />} />
      </Routes>
      <Footer />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
