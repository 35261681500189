import './Goals.css';
import image1 from '../../../assets/images/WhatsApp-Image-2023-06-03-at-10.01.45-1-e1686142268815.jpeg';
function Goals() {
  return (
    <div className="goals">
      <section className="goals-section-1">
        <div></div>
      </section>
      <div className="goals-background-image">
        <div className="goals-background-cover">
          <section className="goals-section-2">
            <img src={image1} alt="" />
            <div>
              <h3>LES OBJECTIFS DU PROJET</h3>
              <h4>OBJECTIF GÉNÉRAL DU PROJET</h4>
              <p>
                Mettre en place un pôle d'innovation entrepreneuriale pour la
                formation professionnelle, l'apprentissage, l'emploi,
                l'insertion des jeunes et l'agrobusiness afin de promouvoir le
                développement économique et social.
              </p>
            </div>
          </section>
          <section className="goals-section-3">
            <div className="goals-section-3-box-1">
              <h4>PRINCIPAUX BÉNÉFICIAIRES DU PROJET</h4>
              <ul>
                <li>
                  Jeunes filles et garçons scolarisés sans aucune qualification
                  professionnelle.
                </li>
                <li>
                  Jeunes ayant terminé anticipativement leurs études et sans
                  emploi.
                </li>
                <li>
                  Les personnes âgées souhaitant se reconvertir dans une
                  activité agro-industrielle.
                </li>
                <li>
                  Les jeunes filles et garçons souhaitant s'installer à leurs
                  propres comptes après la formation.
                </li>
                <li>
                  Les Motos taximen souhaitant se reconvertir aux activités
                  agro-industrielles.
                </li>
                <li>
                  Les leaders de Coopératives/Organisations de producteurs
                  (Leaders de groupes communautaires de production)
                </li>
                <li>
                  Les responsables (fonctionnaires, hommes en tenu et)
                  souhaitant apprendre et créer une activité génératrice de
                  revenus ou un projet soutenable en préparation de leur future
                  retraite.
                </li>
              </ul>
            </div>
            <div className="goals-section-3-box-2">
              <h4>OBJECTIF SPÉCIFIQUE DU PROJET</h4>
              <ul>
                <li>
                  Faire du département du Noun un véritable pôle de
                  développement des activités agro-industrielles au Cameroun
                </li>
                <li>
                  Construire et renforcer la dynamique communautaire autour de
                  l'emploi et la réinsertion socio-économique des jeunes et des
                  femmes
                </li>
                <li>
                  Promouvoir l'innovation entrepreneuriale et l'agrobusiness par
                  des méthodes et des technologies nouvelles
                </li>
                <li>Faire des jeunes des créateurs de richesse ;</li>
                <li>Promouvoir la création de 2000 emplois</li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Goals;
