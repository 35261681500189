function AdmissionUniteIncubation() {
  return (
    <div className="recrutement-formateurs">
      <section className="recrutement-formateurs-section-1">
        <div></div>
      </section>
      <div style={{ backgroundColor: '#FFF' }}>
        <div className="recrutement-formateurs-background-image">
          <div className="recrutement-formateurs-background-cover-image">
            <div className="recrutement-formateurs-container">
              <h1>
                APPEL À CANDIDATURE POUR LE RECRUTEMENT DES FORMATEURS POUR LE
                COMPTE DE L'ANNÉE DE FORMATION 2024-2025
              </h1>
              <h2>CONTEXTE</h2>
              <p>
                Le Centre de Formation Professionnelle et de Promotion de
                l'Emploi Nja'moun du Cameroun (CEFPEN) par le biais de l'Unité
                d'Incubation et de l'Innovation entend contribuer de manière
                soutenable à l'éclosion du génie créatif de la jeunesse et à les
                traduire en initiatives entrepreneuriales innovantes. Ainsi, il
                informe les porteurs d'idées/projets innovants originaire du
                département du Noun ou résidant / âgés de 15 ans au moins au 1er
                janvier 2025 qu'il est lancé à leur intention, un appel à
                candidature pour l'admission de 25 apprenants au CEFPEN, dans
                les secteurs prioritaires suivants:{' '}
                <strong>
                  Industries du Bâtiment et Habitat Durable; Technologies du
                  futur et innovation; l'Agro-business et les Industries
                  créatives, artisanal et tourisme
                </strong>
              </p>
              <p>
                Les candidats retenus bénéficieront d'un programme spécial
                d'incubation de 12 à 16 semaines.
              </p>

              <h2>Dossier de Candidature</h2>
              <p>Les dossiers de candidature comprendront :</p>
              <ul>
                <li>Une demande timbrée;</li>
                <li>
                  Une photocopie de l'une des pièces suivantes: carte nationale
                  d'identité, passeport ou un récépissé en cours de validité;
                </li>
                <li>
                  Un CV portant la mention « Je certifie exactes toutes les
                  informations ci-dessus énoncées » avec des références;
                </li>
                <li>Un certificat médical datant de moins d'un mois;</li>
                <li>
                  Une fiche de présentation synoptique de son projet et son
                  produit le cas échéant dument remplit en ligne ou à
                  télécharger sur le site web du Centre Nja'moun à l'adresse
                  www.njamoun.org. La fiche peut être retirée au CEFPEN sis à
                  Koutaba, après la gare routière en allant vers Foumban
                  immeuble Fleur de Lys.
                </li>
              </ul>
              <h2>Critères de sélection</h2>
              <ul>
                <li>Fournir un dossier complet</li>
                <li>
                  Présenter un projet à caractère innovant et à fort potentiel
                  économique ;
                </li>
                <li>
                  Avoir un projet/idée d'entreprise à fort Impact
                  socio-économique et environnemental;
                </li>
                <li>
                  Faire preuve de dynamisme, de forte motivation, d'agilité et
                  d'abnégation;
                </li>
                <li>Être disposé à suivre les cours en présentiel au CEFPEN</li>
                <li>
                  Savoir lire, écrire et parler une des langues officielles
                  (Français/Anglais) serait un atout ;
                </li>
              </ul>
              <p>
                Les dossiers de candidatures sont déposés auprès du comité local
                chargé de réceptionner les candidatures mis sur pied à cet effet
                (chef de village, Comité de développement…). Les dossiers
                peuvent également être soumis par mail au njamounc@gmail.com ou
                par WhatsApp aux numéros 656762752 / 679061386 / 694819392 ou
                déposés au secrétariat du CEFPEN sis à Koutaba, après la gare
                routière en allant vers Foumban immeuble Fleur de Lys au plus
                tard le 31 Juillet 2024 à 15h30 précises, délai de rigueur.
              </p>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: 18,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <span style={{ textDecoration: 'underline' }}>NB.</span> Tout
                dossier incomplet, en retard ou acheminé par d'autre moyen ne
                fera l'objet d'aucun traitement
              </p>
              <p>
                Les candidats présélectionnés sur étude de dossiers passeront
                devant un jury pour la sélection définitive.
              </p>
              <p>
                Pour toute information complémentaire, prendre attache avec la
                Direction des Etudes, de la Recherche et de l'Innovation ou la
                Direction de l'Unité d'Incubation et de l'Innovation du CEFPEN
                sis à Koutaba, après la gare routière en allant vers Foumban
                immeuble Fleur de Lys aux contacts suivants:{' '}
                <strong>Tel</strong>. 694819392/692848010
              </p>
              <p>
                <span
                  style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                >
                  E-mail.
                </span>
                <span style={{ color: 'blue', textDecoration: 'underline' }}>
                  {' '}
                  njamounc@gmail.com
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdmissionUniteIncubation;
