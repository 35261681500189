import './About_us.css';
import image1 from '../../../assets/images/WhatsApp-Image-2023-06-03-at-10.00.42-e1686140181169.jpeg';
import image2 from '../../../assets/images/about_us/Ngouwu_Raima.svg';
import image3 from '../../../assets/images/about_us/Abdou_Karim_LAYOU_MBOUTYANDI.svg';
import image4 from '../../../assets/images/about_us/aliyou.svg';
import image5 from '../../../assets/images/about_us/Sapit_AJARA.svg';
import image6 from '../../../assets/images/about_us/directeur.svg';
import image7 from '../../../assets/images/about_us/hassan.svg';

function AboutUs() {
  return (
    <div className="about-us">
      <section className="about-us-section-1">
        <div></div>
      </section>
      <div style={{ backgroundColor: '#FFFFFF' }}>
        <div className="about-us-backgroung-image">
          <div className="about-us-backgroung-cover-image">
            <section className="about-us-section-2">
              <img src={image1} alt="" />
              <p>
                Notre initiative vise à contribuer de manière soutenable à
                l'éclosion du génie créatif de la jeunesse et à les traduire en
                initiatives entrepreneuriales innovantes pouvant contribuer au
                développement social et économique durable du Cameroun. Il
                s'agit d'un cadre de création et de maturation d'entreprises
                viables et de renforcement des capacités entrepreneuriales avec
                un accent sur l'autonomisation socioéconomique des jeunes et
                femmes visant la réduction des inégalités.
              </p>
            </section>
            <section className="about-us-section-3">
              <p>
                L'approche intégrée et inclusive permet de contribuer de manière
                holistique à plusieurs Objectifs de Développement Durables et
                prioritairement les ODD1 qui vise à éliminer la pauvreté sous
                toutes ses formes, ODD2 qui vise à éliminer la faim, assurer la
                sécurité alimentaire, améliorer la nutrition et promouvoir
                l'agriculture durable ; ODD4 sur l'accès de tous à une éducation
                de qualité, sur un pied d'égalité, et promouvoir les
                possibilités d'apprentissage tout au long de la vie; et l'ODD5
                sur égalité des sexes et l'autonomisation de toutes les femmes
                et les filles.
              </p>
            </section>
            <p className="about-team-title">
              Équipe dirigeante du centre Nja'moun
            </p>
            <section className="about-us-section-5">
              <div className="about-us-section-4-item">
                <img src={image6} alt="" />
                <div>
                  <h3>Mahomet MOULION</h3>
                  <p>
                    <strong>Directeur Général du Centre Nja'Moun</strong>
                  </p>
                  <p style={{ marginTop: 16, fontSize: 14 }}>
                    Titulaire d'un Mastère en administration publique, d'un 2em
                    Master en Politique et Stratégie de Défense à l'Institut des
                    Hautes Études Internationales de Paris (HEIP) en France,
                    d'un Mastère en comptabilité et Gestion à l'Université Koffi
                    Annan de Guinée Conakry et d'un Diplôme en réponse
                    humanitaire de l'université de Harvard aux États-Unis. Il a
                    occupé les postes d'auditeur Associé et Consultant avec le
                    cabinet international KMPG à Kinshasa en RDC, Expert
                    Consultant en PME/PMI à ISORIS en République de Guinée et a
                    enseigné dans plusieurs institutions de formation en Guinée
                    et en France.
                  </p>
                </div>
              </div>
            </section>
            <section className="about-us-section-4">
              <div className="about-us-section-4-item">
                <img src={image4} alt="" />
                <div>
                  <h3>Aliyou MFOUATIE NJIKAM</h3>
                  <p>
                    <strong>
                      Directeur des Études, de la Recherche et de l'Innovation
                    </strong>
                  </p>
                  <p style={{ marginTop: 16, fontSize: 14 }}>
                    Expert en Coopération Internationale, Management
                    Environnemental et Développement Durable. Diplômé de
                    l'Institut des Relations Internationales du Cameroun (IRIC).
                    Il a mené des études diagnostic en vue de l'élaboration de
                    la Politique Urbaine Nationale du Cameroun avec le cabinet
                    2E&Partner. Il a également participé à l'élaboration de
                    l'Agenda21 Local de la Commune de Douala 1er en tant que
                    consultant dans le cadre du projet UNESCO Héritage for
                    Sustainable City (USC). Précédemment Point Focal Objectif de
                    Développement Durable au sein de l'association FRESH
                    IMPETUS.
                  </p>
                </div>
              </div>
              <div className="about-us-section-4-item">
                <img src={image2} alt="" />
                <div>
                  <h3>Ngouwu Raima</h3>
                  <p>
                    <strong>
                      Directrice Administrative et Financiere, chargée des
                      Resources Humaines.
                    </strong>
                  </p>
                  <p style={{ marginTop: 16, fontSize: 14 }}>
                    Titulaire d'une Licence en Gestion des Ressources Humaines.
                    Précédement Directrice de la société cooperative des
                    producteurs de Riz, 0cooperative partenaire du PDRM2 finance
                    par la BID et l'état du Cameroun. A également occupé les
                    fonctions de Chargée des Operations à Cyberix sarl,
                    Responsable des Ressources humaines à Medibest-sarl.
                  </p>
                </div>
              </div>
              <div className="about-us-section-4-item">
                <img src={image3} alt="" />
                <div>
                  <h3>Abdou Karim LAYOU MBOUTYANDI</h3>
                  <p>
                    <strong>
                      Directeur de l'unité d'Incubation et de l'Innovation
                      Entrepreneuriale(U2iE).
                    </strong>
                  </p>
                  <p style={{ marginTop: 16, fontSize: 14 }}>
                    Diplômé en économie et management de projet à l'Institut
                    Supérieur Polytechnique de Antananarivo, Madagascar. Après
                    un stage professionnel au Fonds des Nations Unies pour
                    l'Alimentation et l'Agriculture (FAO), il a été Directeur
                    pour l'Ocean Indien de l'Agence de Transport et Mobilité -
                    ATM International à Madagascar et a servi comme Consultant
                    dans le Cabinet d'Avocat KARIE à Foumban au Cameroun.
                  </p>
                </div>
              </div>
              <div className="about-us-section-4-item">
                <img src={image5} alt="" />
                <div>
                  <h3>Sapit AJARA</h3>
                  <p>
                    <strong>Assistante de Direction à NJA'MOUN</strong>
                  </p>
                  <p style={{ marginTop: 16, fontSize: 14 }}>
                    Diplômé en trilingue Français, Anglais, allemand à
                    l'université de Dschang, titulaire d'un BTS en gestion des
                    ONG (à IUSTY) et d'un certificat de formation sur la
                    gouvernance et le budget selon les règles de l''USAID, elle
                    a occupé précédemment les postes d'assistante en
                    communication et relation publique à HEREG, Assistante de
                    direction à SOREDIA GROUP et a également exercé comme
                    chargée de la clientèle à GREEN FINANCE CAMEROUN.
                  </p>
                </div>
              </div>
            </section>
            <section className="about-us-section-6">
              <div className="about-us-section-4-item">
                <img src={image7} alt="" />
                <div>
                  <h3>Njiawouo el Hassan</h3>
                  <p>
                    <strong>
                      Surveillant General en charge de la discipline, de la
                      sécurité et de la sûreté au Centre Nja'Mun de Koutaba.
                    </strong>
                  </p>
                  <p style={{ marginTop: 16, fontSize: 14 }}>
                    Après plusieurs formations respectivement en soins
                    paramedicaux montage de projet, leadership, en elevage et
                    recherche de financement, le jeune entrepreuneur a également
                    des formations sur l'Etat de Droit, la citoyennete et la
                    démocratie. Il a occupé les fonctions de Maire Jeune et de
                    président du conseil national de la jeunesse pour
                    l'arrondissement de Koutaba.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
