import image1 from '../../../assets/images/creativeIndustry/two-african-dressmaker-woman-designed-new-red-dress-mannequin-tailor-office-black-seamstress-girls.jpg';
import image2 from '../../../assets/images/creativeIndustry/hotelerie.svg';
import image3 from '../../../assets/images/creativeIndustry/side-view-woman-clay-sculpting.jpg';
import image5 from '../../../assets/images/creativeIndustry/black-videographer-smiling-camera-editing-video-project-post-production-software-working-creative-studio-office.jpg';
import image6 from '../../../assets/images/creativeIndustry/pexels-donald-tong-66134.jpg';
function CreativeInsdustry() {
  const items = [
    {
      image: image1,
      title: 'Stylisme, modélisme et Couture',
    },
    {
      image: image2,
      title: 'Hôtellerie et Restauration',
    },
    {
      image: image3,
      title: 'Tissage, art du bronze, des pierres et du bois',
    },
    {
      image: image5,
      title: 'Graphisme de production, publicité et montage audiovisuel',
    },
    {
      image: image6,
      title: 'Cinéma et production artistique et culturelle',
    },
  ];
  return (
    <div className="building-industry">
      <section className="building-industry-section-1">
        <div></div>
      </section>
      <div style={{ backgroundColor: '#FFFFFF' }}>
        <section className="building-industry-section-2">
          <div className="building-industry-divider"></div>
          <h1>INDUSTRIES CRÉATIVES, ARTISANALES ET TOURISMES</h1>
          <div className="building-industry-section-2-items">
            {items.map((item) => (
              <div className="building-industry-section-2-item">
                <img src={item.image} alt="" />
                <h3>{item.title}</h3>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default CreativeInsdustry;
