import './Contact.css';
import { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function Contact() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function sendEmail(e) {
    handleOpen();
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    let finalMessage = `Email: ${formJson.email}\nTéléphone: ${formJson.phone}\n${formJson.message}`;
    console.log(finalMessage);

    fetch('https://www.njamoun.org/send_mail.php', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json, charset=utf-8',
      },
      body: JSON.stringify({
        subject: formJson.nom,
        message: formJson.message,
      }),
    })
      .then((response) => {
        handleClose();
        console.log(response);
        document.contactForm.reset();
        return response.text();
      })
      .then((data) => {
        console.log(data);
      })
      .catch(() => {
        handleClose();
      });
  }

  return (
    <div className="contact">
      <section className="contact-section-1"></section>
      <div className="contact-background">
        <div className="contact-background-image">
          <div className="contact-background-cover-image">
            <section className="contact-section-2">
              <div className="contact-form">
                <form name="contactForm" method="post" onSubmit={sendEmail}>
                  <input type="text" name="nom" placeholder="Nom" required />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                  />
                  <input
                    type="tel"
                    name="phone"
                    placeholder="Téléphone"
                    required
                  />
                  <textarea
                    name="message"
                    placeholder="Message"
                    rows="5"
                    required
                  ></textarea>
                  <button type="submit">Envoyer</button>
                </form>
              </div>
              <div className="contact-section-2-box-2">
                <div>
                  <h3>EMAIL</h3>
                  <p>njamounc@gmail.com</p>
                </div>
                <div>
                  <h3>TÉLÉPHONE</h3>
                  <p>+237679061399</p>
                </div>
                <div>
                  <h3>TÉLÉPHONE</h3>
                  <p>+237656762752</p>
                </div>
                <div>
                  <h3>LOCALISATION</h3>
                  <p>KOUTABA, Tayandi, Immeuble Fleur de Lys, route Foumban</p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Contact;
