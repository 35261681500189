import image1 from '../../../assets/images/vue-laterale-femmes-travaillant-exterieur-1-e1684492425563.jpg';
import './Vision_mission.css';
function VisionMission() {
  return (
    <div className="vision-mission">
      <section className="vision-mission-section-1">
        <div></div>
      </section>
      <div style={{ backgroundColor: '#FFFFFF' }}>
        <div className="vision-mission-background-image">
          <div className="vision-mission-background-cover-image">
            <section className="vision-mission-section-2">
              <img src={image1} alt="" />
              <p>
                Les promoteurs de ce projet voudraient partager avec les
                communautés du département du Noun, les dividendes de leurs
                acquis et expériences de longues années au service du
                développement avec ceux qui sont dans le besoin et contribuer
                ainsi à la concrétisation de la vision de Sa Majesté le Sultan
                NFORIFUM Nabil MBOMBO NJOYA, 20
                <sup>ème </sup>
                Roi de la dynastie Bamoun, qui projette le{' '}
                <em>
                  « ROYAUME BAMOUN A L'HORIZON 2050 DANS UN CAMEROUN EMERGENT :
                  BIEN-ETRE ET COHESION SOCIALE »
                </em>
                . Ils ont la ferme détermination de soutenir un modèle
                économique novateur qui repose sur le renforcement des modes de
                production et de consommation durables axés sur la gestion
                rationnelle des ressources locales et des potentialités
                existantes.
              </p>
            </section>
            <section className="vision-mission-section-3">
              <p>
                Faire du département du Noun un véritable pôle de développement
                des activités agro-industrielles au Cameroun
              </p>
              <ul>
                <li>
                  Construire et renforcer la dynamique communautaire autour de
                  la réinsertion socio-économique des jeunes
                </li>
                <li>
                  Soutenir les opportunités visant l'insertion socio-économique
                  des jeunes et des femmes
                </li>
                <li>
                  Promouvoir l'innovation entrepreneuriale et l'agrobusiness par
                  des méthodes et des technologies nouvelles
                </li>
                <li>Faire des jeunes des créateurs de richesse ;</li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisionMission;
