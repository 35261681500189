import './unite_incubation.css';
function UniteIncubation() {
  return (
    <div className="unite-incubation">
      <section className="unite-incubation-section-1">
        <div></div>
      </section>
      <div style={{ backgroundColor: '#fff' }}>
        <div className="unite-incubation-background-image">
          <div className="unite-incubation-background-cover-image">
            <div className="unite-incubation-container">
              <section>
                <h1>UNITE D'INCUBATION ET DE L'INNOVATION ENTREPRENEURIALE</h1>
                <h2>PRESENTATION</h2>
                <p>
                  L'unité d'incubation et de l'innovation entrepreneuriale
                  (U2iE) est une direction créée au sein du centre de formation
                  professionnelle et de la promotion de l'emploi Nja'moun de
                  Koutaba (CEFPEN) pour contribuer de manière soutenable à
                  l'éclosion du génie créatif de la jeunesse et à les traduire
                  en initiatives entrepreneuriales innovantes, pouvant
                  contribuer au développement social et économique durable. Il
                  s'agit d'un cadre de création et de maturation d'entreprises
                  viables, un lieu de renforcement des capacités
                  entrepreneuriales avec un accent sur l'autonomisation
                  socio-économique des jeunes et femmes visant la réduction des
                  inégalités.
                </p>
                <h2>NOS OBJECTIFS</h2>
                <ul>
                  <li>
                    Contribuer de manière soutenable à l'éclosion du génie
                    créatif de la jeunesse et à les traduire en initiatives
                    entrepreneuriales innovantes, pouvant contribuer au
                    développement social et économique durable;
                  </li>
                  <li>
                    Renforcer les compétences techniques des jeunes porteurs de
                    projets à travers une offre de service d'incubation;
                  </li>
                  <li>
                    Accompagner les incubés à la création de leurs
                    micro-entreprises
                  </li>
                  <li>
                    Accompgner les entreprises dans la levée de fonds en : (i)
                    mettant à leur dispositions les informations sur les sources
                    potentielles de financement de projet d'entreprise; (ii)
                    facilitant l'accès au financement auprès des mécanismes de
                    finacement existant (fonds nationaux, concours
                    nationaux/internationaux, institutions de micro-finance,
                    banques...)
                  </li>
                  <li>
                    Promouvoir l'innovation entrepreneuriale des jeunes et des
                    femmes par des méthodes et des technologies nouvelles;
                  </li>
                  <li>
                    Faire des jeunes et des femmes des créateurs de richesse.
                  </li>
                </ul>
                <h2>NOS MISSIONS</h2>
                <ul>
                  <li>
                    Orienter et guider les apprenants dans le choix de leurs
                    métiers ou initiatives entrepreneuriale;
                  </li>
                  <li>
                    Renforcer les capacités des apprenants en compétences
                    entrepreneuriales, compétences de vie, leadership, au
                    montage de projets (élaboration des plans d'affaires
                    compétitifs individuels et collectifs, montage financier);
                  </li>
                  <li>
                    Soutenir l'organisation des stages pratiques, l'encadrement
                    et la recherche des opportunités de financement pour les
                    initiatives entrepreneuriales au profit des apprenants et
                    particuliers (retraités et autres);
                  </li>
                  <li>
                    Accueillir et orienter les fonctionnaires et retraités qui
                    veulent se reconvertir ou entrependre une activité autre.
                  </li>
                </ul>
                <h2>NOS PRESTATIONS</h2>
                <ul>
                  <li>Montage de projets</li>
                  <li>
                    Elaboration des plans d'affaire individuels et collectifs
                  </li>
                  <li>Montage financier</li>
                  <li>Recherche de financement</li>
                  <li>Conseils en entrepreneuriat</li>
                  <li>Conseil fiscal</li>
                  <li>Création et d'entreprises</li>
                  <li>Prototypage</li>
                  <li>Pitch et marketing</li>
                  <li>
                    Design des produits et procédés technologiques de
                    co-production connectée
                  </li>
                  <li>
                    Assistance à la conformité aux normes internationales et
                    assurance qualité de produits
                  </li>
                  <li>
                    Assistance à la protection de la propriété intellectuelle
                  </li>
                  <li>Assistance à la normalisation</li>
                  <li>Déclaration fiscale</li>
                </ul>
                <h2>BENEFICIAIRES</h2>
                <ul>
                  <li>
                    Jeunes filles et garçons scolarisés sans aucune
                    qualification professionnelle
                  </li>
                  <li>Jeunes ayant terminés leurs études et sans emploi</li>
                  <li>
                    Les personnes âgées souhaitant se reconvertir dans une
                    activité agro-industrielle
                  </li>
                  <li>
                    Les jeunes filles et garçons souhaitant s'installer à leur
                    propre compte après la formation
                  </li>
                  <li>
                    Les responsables (fonctionnaires, hommes en tenue et autres)
                    souhaitant créer une activité génératrice des revenus ou un
                    projet soutenable en préparation de leur future retraite
                  </li>
                  <li>
                    Les motos taximans souhaitant se reconvertir aux activités
                    agro-industrielles
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UniteIncubation;
