function Album(params) {
  return (
    <div className="album">
      <section className="album-section-1">
        <div></div>
      </section>
    </div>
  );
}

export default Album;
