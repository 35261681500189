import image1 from '../../../assets/images/technologyIndustry/cafe-beautiful-business-african-american.jpg';
import image2 from '../../../assets/images/technologyIndustry/network-1572617_640.jpg';
import image3 from '../../../assets/images/technologyIndustry/smartphone-8014286_640.jpg';
import image4 from '../../../assets/images/technologyIndustry/code-1076536_640.jpg';
function TechnologyInsdustry() {
  const items = [
    {
      image: image1,
      title: 'Secrétariat comptable',
    },
    {
      image: image2,
      title: 'Maintenance et réseaux informatiques',
    },
    {
      image: image3,
      title: "Développement d'applications",
    },
    {
      image: image4,
      title: 'Webmestre',
    },
  ];
  return (
    <div className="building-industry">
      <section className="building-industry-section-1">
        <div></div>
      </section>
      <div style={{ backgroundColor: '#FFFFFF' }}>
        <section className="building-industry-section-2">
          <div className="building-industry-divider"></div>
          <h1>INFORMATIQUE, NOUVELLES TECHNOLOGIES ET TECHNOLOGIES DU FUTUR</h1>
          <div className="building-industry-section-2-items">
            {items.map((item) => (
              <div className="building-industry-section-2-item">
                <img src={item.image} alt="" />
                <h3>{item.title}</h3>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default TechnologyInsdustry;
