import './calendar.css';
function Calendar() {
  const activities = [
    {
      activity: 'Pré-inscription des apprenants',
      start: '1er Juillet 2024',
      end: '06 Septembre 2024',
      bgColor: false,
    },
    {
      activity: 'Recrutement des enseignants',
      start: '1er Juillet 2024',
      end: '31 Août 2024',
      bgColor: false,
    },
    {
      activity: 'Test psychotechnique',
      start: '09 Septembre 2024',
      end: '13 Septembre 2024',
      bgColor: false,
    },
    {
      activity: "Dépôt des dossiers d'admission et inscriptions des apprenants",
      start: '16 Septembre 2024',
      end: '27 Septembre 2024',
      bgColor: false,
    },
    {
      activity: 'Rentrée des enseignants et journée pédagogique',
      start: '16 Septembre 2024',
      end: '27 Septembre 2024',
      bgColor: false,
    },
    {
      activity: 'Rentrée de formation pour les apprenants',
      start: '1er Octobre 2024',
      end: '/',
      bgColor: false,
    },
    {
      activity: 'Formation théorique',
      start: '1er Octobre 2024',
      end: '20 Décembre 2024',
      bgColor: false,
    },
    {
      activity: 'Congé de Noël',
      start: '20 Décembre 2024',
      end: '05 Janvier 2025',
      bgColor: true,
    },
    {
      activity: 'Formation en alternance',
      start: '06 Janvier 2025',
      end: '09 mai 2025',
      bgColor: false,
    },
    {
      activity: 'Stage en entreprise',
      start: '12 Mai 2025',
      end: '29 Août 2025',
      bgColor: false,
    },
  ];
  return (
    <div className="calendar">
      <section className="calendar-section-1">
        <div></div>
      </section>
      <div style={{ backgroundColor: '#fff' }}>
        <div className="calendar-background-image">
          <div className="calendar-background-cover-image">
            <div className="calendar-container">
              <section>
                <h1>CALENDRIER ACADEMIQUE</h1>

                <table>
                  <tr>
                    <th>Activités</th>
                    <th>Début</th>
                    <th>Fin</th>
                  </tr>
                  {activities.map((item) => (
                    <tr>
                      <td
                        style={{
                          fontWeight: 600,
                          backgroundColor: item.bgColor ? '#fbd4b4' : null,
                        }}
                      >
                        {item.activity}
                      </td>
                      <td
                        style={{
                          fontStyle: item.bgColor ? 'italic' : null,
                          backgroundColor: item.bgColor ? '#fbd4b4' : null,
                        }}
                      >
                        {item.start}
                      </td>
                      <td
                        style={{
                          fontStyle: item.bgColor ? 'italic' : null,
                          backgroundColor: item.bgColor ? '#fbd4b4' : null,
                        }}
                      >
                        {item.end}
                      </td>
                    </tr>
                  ))}
                </table>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calendar;
