import './Actuality.css';
import { useNavigate } from 'react-router-dom';
import image1 from '../../../assets/images/actuality/student.png';
import image2 from '../../../assets/images/actuality/teacher.png';
import image3 from '../../../assets/images/actuality/best-project.png';
import image4 from '../../../assets/images/actuality/nguon_patenariat.png';
import image5 from '../../../assets/images/actuality/autorite_koutaba.png';
import image6 from '../../../assets/images/actuality/team.png';
import image7 from '../../../assets/images/actuality/stagiaires.png';

function Actuality() {
  let navigate = useNavigate();
  function navigateTo(route) {
    if (route !== '') {
      navigate(route);
    }
  }

  const actualities = [
    {
      title:
        "RECRUTEMENT ET FORMATION DES STAGIAIRES POUR LA COMPAGNE DE SENSIBILISATION PAR LE CENTRE NJA'MOUN",
      content: `En prélude à la campagne de sensibilisation de la population sur les services offerts par le Centre Nja'moun, les stagiaires ont été recrutés. 
      ces derniers ont suivi une séance de formation ce lundi 12 août 2024.`,
      image: image7,
      page: '/stagiaires',
      more: true,
    },
    {
      title:
        "NOTE D'INFORMATION PORTANT SUR FERMETURE DU CENTRE NJA'MOUN DU 19 AU 22 août 2024",
      content: `Dans le cadre de l'atteinte de ces objectifs qui s'inscrit dans le domaine central de l'entrepreneuriat et de l'emploi, 
      le staff du centre NJA'MOUN entend avoir une retraite annuelle qui se tiendra du 19-22 août 2024. A cet effet, le centre sera fermé ces jours, 
      et ne sera ouvert que le jeudi 23 août 2024 à 8h00.`,
      image: image6,
      page: '',
      more: false,
    },
    {
      title: 'RENCONTRE AVEC LES AUTORITÉS DE LA COMMUNE DE KOUTABA',
      content: `Dans le but du lancement de ses activités, le centre NJA'MOUN a eu une session de travail avec 
      l'adjoint au sous-préfet et le Maire de la commune de KOUTABA, pour une bref présentation du centre et ces appels d'offres.`,
      image: image5,
      page: '',
      more: false,
    },
    {
      title:
        "APPEL À CANDIDATURE POUR LES PRE-INSCRIPTIONS DES APPRENANTS POUR LE COMPTE DE L'ANNÉE DE FORMATION 2024-2025",
      content: `Le centre nja'moun lance un appel pour les pré-inscriptions
                  des apprenants pour le compte de l'année de formation
                  2024-2025.`,
      image: image1,
      page: '/candidature-apprenant',
      more: true,
    },
    {
      title: `APPEL À CANDIDATURE POUR LE RECRUTEMENT DES FORMATEURS POUR LE
                  COMPTE DE L'ANNÉE DE FORMATION 2024-2025`,
      content: `Le centre nja'moun lance un appel pour le recrutement des
                  formateurs pour le compte de l'année de formation 2024-2025.`,
      image: image2,
      page: '/recrutement-formateurs',
      more: true,
    },
    {
      title: `APPEL À CANDIDATURE POUR LA SELECTION DES MEILLEURS PROJETS
                  POUR L'ADMISSION À L'UNITÉ D'INCUBATION DU CEFPEN POUR LE
                  COMPTE DE L'ANNÉE 2024-2025`,
      content: `Le centre nja'moun lance un appel pour la selection des
                  meilleurs projets pour l'admission à l'unité d'incubation pour
                  le compte de l'année 2024-2025.`,
      image: image3,
      page: '/admission-unite-incubation',
      more: true,
    },
    {
      title: "CENTRE NJA'MOUN ET FONDATION DU NGUON",
      content: `Signature d'une convention entre le Centre Nja'Moun et la
                  Fondation Nguon ce Vendredi 28 juin 2024. L'objectif principal
                  est de saisir l'opportunité de la célébration du NGUON 2024
                  pour promouvoir l'entreprenariat innovant des jeunes et des
                  femmes comme vecteur de la vision royale de développement.`,
      image: image4,
      page: '/nguon',
      more: true,
    },
  ];
  return (
    <div className="actuality">
      <section className="actuality-section-1">
        <div></div>
      </section>
      <div className="actuality-dark-cover">
        <div className="actuality-container">
          <h1>
            ACTUALITÉS AU CENTRE DE FORMATION PROFESSIONNELLES ET DE LA
            PROMOTION DE L'EMPLOI NJA'MOUN
          </h1>
          <section className="actuality-section-2">
            {actualities.map((actuality) => (
              <div
                className="actuality-item"
                style={{ cursor: actuality.more ? 'pointer' : 'initial' }}
                onClick={() => {
                  navigateTo(actuality.page);
                }}
              >
                <div
                  className="actuality-item-img"
                  style={{ backgroundImage: `url(${actuality.image})` }}
                ></div>
                <div className="actuality-item-text">
                  <p className="actuality-item-p-1">{actuality.title}</p>
                  <p className="actuality-item-p-2">{actuality.content}</p>
                  {actuality.more ? (
                    <div style={{ textAlign: 'right', marginTop: 10 }}>
                      <button>Plus de détails</button>
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
          </section>
        </div>
      </div>
    </div>
  );
}

export default Actuality;
