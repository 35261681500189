import './recrutement_formateurs.css';
function RecrutementFormateurs() {
  return (
    <div className="recrutement-formateurs">
      <section className="recrutement-formateurs-section-1">
        <div></div>
      </section>
      <div style={{ backgroundColor: '#FFF' }}>
        <div className="recrutement-formateurs-background-image">
          <div className="recrutement-formateurs-background-cover-image">
            <div className="recrutement-formateurs-container">
              <h1>
                APPEL À CANDIDATURE POUR LE RECRUTEMENT DES FORMATEURS POUR LE
                COMPTE DE L'ANNÉE DE FORMATION 2024-2025
              </h1>
              <h2>CONTEXTE</h2>
              <p>
                Dans le contexte d'une croissance continue tant sur les plans
                culturels, qu'économiques, le Cameroun veut relever le défi de
                l'acquisition et de la diversification des connaissances et des
                compétences pour sa population scolaire de l'élémentaire au
                supérieur, afin de diffuser les progrès technologiques,
                scientifiques et plus largement les compétences sociales qui
                fondent la citoyenneté camerounaise. Le capital humain constitue
                l'un des fondements majeurs de tout développement. Les
                politiques de développement durable ne peuvent être entreprises
                qu'en s'appuyant sur des hommes et des femmes bien formés, de
                qualité et capables de conduire de telles politiques. Pour ce
                faire, la formation professionnelle est une exigence pour la
                valorisation des ressources humaines et le renforcement des
                capacités. Elle permet d'asseoir des compétences en s'appuyant
                sur une politique de formation à la fois pertinente et
                cohérente.
              </p>
              <p>
                CEFPEN entend offrir des enseignements et des formations
                techniques et professionnels répondant aux besoins de main
                d'œuvre de l'économie, tant quantitativement que
                qualitativement.
              </p>
              <p>
                Pour atteindre cette vision, qui s'inscrit dans le domaine
                central de l'entrepreneuriat et de l'emploi, le Centre de
                Formation Professionnelle et de Promotion de l'Emploi Nja'Moun
                du Cameroun (CEFPEN) envisage le recrutement des formateurs
                compétents pour transmettre aux apprenants, au-delà des
                connaissances, des compétences dans les domaines linguistiques,
                d'entrepreneurials, de leadership, du marketing et dans les
                filières des Industries du Bâtiment et Habitat Durable,
                Technologies du future et innovation, Agro business, Industries
                créatives, artisanat et tourisme.
              </p>
              <h2>FONCTIONS/RESPONSABILITES/TACHES</h2>
              <p>
                Sous la responsabilité du Directeur des Etudes, de la Recherche
                et de l'innovation, les formateurs auront pour missions :{' '}
              </p>
              <ul>
                <li>
                  d'assurer les missions classiques dévolues aux enseignants du
                  CEFPEN. A cet effet, il exerce principalement les activités
                  d'enseignement, de recherche et de promotion de l'emploi au
                  sein du CEFPEN;
                </li>
                <li>
                  de contribuer à l'encadrement des apprenants du CEFPEN tant
                  pendant la période des enseignements que pour la période des
                  stages;
                </li>
                <li>de concourir à la vie collective du CEFPEN;</li>
                <li>
                  aider les apprenants à développer une pensée créative, de
                  savoir communiquer sur leurs projets et de travailler en
                  équipe;
                </li>
                <li>
                  Suivre et appliquer les curricula adoptés par le conseil
                  pédagogique du centre;
                </li>
                <li>
                  Préparer les modules de formations en fonction du
                  domaine/filière retenue
                </li>
                <li>
                  d'exécuter toute directive donnée par le Directeur Général ou
                  le Directeur des Etudes et de la Recherche et de l'Innovation
                  dans le cadre des activités de gestion d'apprentissage du
                  CEFPEN.
                </li>
              </ul>
              <h2>COMPETENCES FONCTIONNELLES</h2>
              <ul>
                <li>
                  Justifier des aptitudes à pouvoir aider les apprenants à
                  améliorer leurs compétences dans les domaines ciblés;
                </li>
                <li>
                  Justifier des aptitudes à pouvoir aider les apprenants à
                  trouver un emploi ou à progresser dans leur rôle actuel;
                </li>
                <li>Avoir une bonne connaissance des domaines choisis;</li>
                <li>
                  Avoir une expérience dans l'accompagnement des jeunes porteurs
                  de projets;
                </li>
              </ul>
              <h2>QUALIFICATIONS REQUISES DES FORMATEURS</h2>
              <table className="recrutement-formateurs-tab-1">
                <tr>
                  <td>Education et formations :</td>
                  <td style={{ textAlign: 'start' }}>
                    Détenir un diplôme d'enseignement professionnel ou tout
                    autre diplôme universitaire directement lié au programme à
                    enseigner
                  </td>
                </tr>
                <tr>
                  <td>Expérience :</td>
                  <td>
                    <ul>
                      <li>
                        Justifier d'une expérience professionnelle cumulée de 2
                        années au moins dans le domaine de la formation;
                      </li>
                      <li>
                        Avoir des compétences relationnelles, une capacité à
                        animer et gérer un groupe et à s'adapter aux différents
                        profils des apprenants;
                      </li>
                      <li>Avoir une très bonne capacité rédactionnelle;</li>
                      <li>
                        Avoir une excellente maitrise de la langue française ou
                        de l'anglais;
                      </li>
                      <li>
                        Avoir une expérience pratique dans les domaines et une
                        forte motivation professionnelle orientée résultat
                        maîtriser les langues locales serait un atout.
                      </li>
                    </ul>
                  </td>
                </tr>
              </table>
              <p>
                Pour le nombre de poste, les types des formateurs recherchés
                pour chaque filière et spécialité, veuillez consulter
                attentivement le tableau ci-dessous.
              </p>
              <h2>
                Le nombre de poste et types de formateurs recherchés pour chaque
                filière et spécialités.
              </h2>
              <table className="recrutement-formateurs-tab-2">
                <tr>
                  <th rowSpan={2}>SPECIALITE / MODULES</th>
                  <th colSpan={2}>TYPES DES FORMATEURS</th>
                </tr>
                <tr>
                  <td>Poste d'enseignants vacataires</td>
                  <td>Poste d'enseignants Permanents</td>
                </tr>
                <tr>
                  <td colSpan={3}>Les enseignements fondamentaux</td>
                </tr>
                <tr>
                  <td>Compétences linguistiques: Français</td>
                  <td>1</td>
                  <td rowSpan={6}></td>
                </tr>
                <tr>
                  <td>Compétences linguistiques: Anglais</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Compétences de vie et leadership</td>
                  <td rowSpan={2}>1</td>
                </tr>
                <tr>
                  <td>Education Civique et Citoyenneté</td>
                </tr>
                <tr>
                  <td>Compétences entrepreneuriales</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Marketing, Education Financière</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td colSpan={3}>Industries du Bâtiment et Habitat Durable</td>
                </tr>
                <tr>
                  <td>Électricité et énergies renouvelable</td>
                  <td>1</td>
                  <td rowSpan={8}>1</td>
                </tr>
                <tr>
                  <td>Carrelage et staff bâtiment</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Maçonnerie</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Construction ouvrage métallique (soudure)</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Plomberie et installation sanitaire</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Menuiserie ébéniste</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Peinture, staff et décoration intérieure</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Production des matériaux</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td colSpan={3}>Technologies du future et innovation</td>
                </tr>
                <tr>
                  <td>Assistant de direction option secrétariat bureautique</td>
                  <td>1</td>
                  <td rowSpan={12}>1</td>
                </tr>
                <tr>
                  <td>Assistant de direction option Comptabilité</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>
                    Assistant de direction option gestion des organisations
                  </td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>
                    Assistant de direction option Approvisionnement inventaire
                    et gestion de stock - logistique (gestion des inventaires et
                    des immobilisations)
                  </td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Assistant de direction option Ressources Humaines</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>
                    Assistant de direction option Passation des marchés et
                    acquisitions
                  </td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Maintenance réseau informatique</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>
                    E-commerce : Infographie, graphisme et marketing digital
                  </td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Webmaster</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Intelligence artificielle et applications digitales</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>Montage audiovisuel</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>
                    Télématique : installation des camera/vidéo, tracking
                    system/GPS
                  </td>
                  <td>2</td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    Industries créatives, artisanal et tourisme
                  </td>
                </tr>
                <tr>
                  <td>Styliste modéliste / Couture</td>
                  <td>1</td>
                  <td rowSpan={4}>1</td>
                </tr>
                <tr>
                  <td>Hôtellerie et Restauration</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>Cinéma et production artistique et culturelle</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Tissage art de bronze, de pierre et du bois.</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td colSpan={3}>Agro business</td>
                </tr>
                <tr>
                  <td>Entrepreneuriat agricole</td>
                  <td>1</td>
                  <td rowSpan={8}>1</td>
                </tr>
                <tr>
                  <td>Fertilisation organique / Naturelle</td>
                  <td rowSpan={4}>1</td>
                </tr>
                <tr>
                  <td>Pesti-régulateur</td>
                </tr>
                <tr>
                  <td>Vie des sols</td>
                </tr>
                <tr>
                  <td>Mécanisation Agricole</td>
                </tr>
                <tr>
                  <td>Production animale Durable/ Biologique</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Productions Végétales Durable/ Biologiques</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>
                    Production du biogaz et de pavées écologiques et de charbon
                    écologique (bio char)
                  </td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>TOTAL DE POSTE A POURVOI</td>
                  <td>37</td>
                  <td>4</td>
                </tr>
              </table>
              <h2>PROPOSITION DU CANDIDAT</h2>
              <p>Les dossiers de candidature doivent comprendre :</p>
              <ul>
                <li>
                  Une lettre de motivation adressée au Directeur Général du
                  CEFPEN spécifiant la spécialité choisie ainsi que la ou les
                  modules choisis. Le candidat indiquera sa disponibilité
                  (vacataire ou permanent) s'il est retenu;
                </li>
                <li>
                  Photocopies des diplômes universitaires obtenus et tout autre
                  document certifiant l'aptitude à dispenser la formation
                  choisie;
                </li>
                <li>
                  Un CV détaillé du candidat mettant en relief son ancienneté
                  dans son domaine d'expertise et ses travaux scientifiques
                  réalisés ou en voie de réalisation;
                </li>
                <li>
                  Une copie de la carte d'identité nationale ou du récépissé
                  valide
                </li>
                <li>Photocopie CNI ou récépissé valide</li>
              </ul>
              <p>
                Les propositions sont à déposer sous plis fermé avant le
                <strong>30 Juillet 2024</strong> au secrétariat du Centre
                Nja'Moun sis à Koutaba après la gare routière en allant vers
                Foumban (bâtiment Fleur de Lys), ou à envoyer en format PDF en
                dossier unique également par e-mail aux adresses suivantes:
                <span style={{ color: 'blue' }}> njamounc@gmail.com</span> ou
                par WhatsApp au numéro <strong>(+237) 656762752</strong>.
              </p>
              <p>
                Merci de mentionner en objet «
                <strong>CANDIDATURE FORMATEUR DU CENTRE NJA'MOUN</strong>»
              </p>
              <p>
                <strong>N.B:</strong> Les candidatures féminines sont
                encouragées et seuls les candidats retenus seront contactés.
                Pour toutes les informations complémentaires, veuillez contacter
                les numéros ci-dessus ou consulté le site du centre au
                www.njamoun.org
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecrutementFormateurs;
