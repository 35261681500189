import './Footer.css';

function Footer() {
  return (
    <>
      <div className="footer">
        <section className="footer-section-1">
          <div className="footer-box-1">
            <p>SUIVEZ NOUS SUR</p>
            <div style={{ display: 'flex' }}>
              <div className="footer-box-1-icon">
                <i class="fa fa-facebook-f"></i>
              </div>
              <div className="footer-box-1-icon">
                <i class="fa fa-twitter"></i>
              </div>
              <div className="footer-box-1-icon">
                <i class="fa fa-youtube-play"></i>
              </div>
            </div>
          </div>
          <div className="footer-box-2">
            <p>LIENS UTILES</p>
            <div
              className="footer-link"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <i class="fa fa-handshake-o"></i>
              <p style={{ marginLeft: 8 }}>agenda JA'MOUN</p>
            </div>
            <div
              className="footer-link"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <i class="fa fa-handshake-o"></i>
              <p style={{ marginLeft: 8 }}>politique de confidentialité</p>
            </div>
          </div>
        </section>
        <section className="footer-section-2">
          <p>© NJA' MOUN</p>
          <div></div>
          <p>Développé par ZOUNEDOU</p>
        </section>
      </div>
    </>
  );
}

export default Footer;
