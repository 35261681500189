import image1 from '../../../assets/images/pastoralIndustry/potatoes-1866415_640.jpg';
import image2 from '../../../assets/images/pastoralIndustry/sheep-3025866_640.jpg';
import image3 from '../../../assets/images/pastoralIndustry/women-298677_640.jpg';

function PastoralIndustry() {
  const items = [
    {
      image: image1,
      title: 'Production végétale Durable et Biologique',
    },
    {
      image: image2,
      title: 'Production animale Durable et Biologique',
    },
    {
      image: image3,
      title: 'Agro-industrie',
    },
    {
      image: image3,
      title:
        'Production du biogaz et de pavées écologiques et de charbon écologique (bio char)',
    },
  ];
  return (
    <div className="building-industry">
      <section className="building-industry-section-1">
        <div></div>
      </section>
      <div style={{ backgroundColor: '#FFFFFF' }}>
        <section className="building-industry-section-2">
          <div className="building-industry-divider"></div>
          <h1>AGRO BUSINESS</h1>
          <div className="building-industry-section-2-items">
            {items.map((item) => (
              <div className="building-industry-section-2-item">
                <img src={item.image} alt="" />
                <h3>{item.title}</h3>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default PastoralIndustry;
