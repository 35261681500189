import './building_industry.css';
import image1 from '../../../assets/images/buildingIndustry/electric-1080584_640.jpg';
import image2 from '../../../assets/images/buildingIndustry/angle-grinder-429757_1280-1.jpg';
import image4 from '../../../assets/images/buildingIndustry/smiling-young-african-american-builder-uniform-holding-roller-brush-with-paint-brush-isolated-blue-background.jpg';
import image5 from '../../../assets/images/buildingIndustry/cement-406822_640.jpg';
import image6 from '../../../assets/images/buildingIndustry/pipes-2672184_640.jpg';
import image7 from '../../../assets/images/buildingIndustry/charpentier-coupant-panneau-forces-defense-principale-interieur-atelier.jpg';
import image10 from '../../../assets/images/buildingIndustry/brick-258938_640.jpg';
import image11 from '../../../assets/images/buildingIndustry/tiles-1478499_640.jpg';

function BuildingInsdustry() {
  const items = [
    {
      image: image1,
      title: 'Électricité et énergies renouvelables',
    },
    {
      image: image2,
      title: 'Construction des ouvrages métalliques',
    },
    {
      image: image4,
      title: 'Peinture bâtiment, staff et décoration intérieure',
    },
    {
      image: image5,
      title: 'Maçonnerie',
    },
    {
      image: image6,
      title: 'Plomberie -installation sanitaire',
    },
    {
      image: image7,
      title: 'Menuiserie Ebénististe',
    },
    {
      image: image10,
      title: 'Production des matériaux de construction',
    },
    {
      image: image11,
      title: 'Carrelage bâtiment',
    },
  ];
  return (
    <div className="building-industry">
      <section className="building-industry-section-1">
        <div></div>
      </section>
      <div style={{ backgroundColor: '#FFFFFF' }}>
        <section className="building-industry-section-2">
          <div className="building-industry-divider"></div>
          <h1>INDUSTRIES DU BÂTIMENT ET HABITAT DURABLE</h1>
          <div className="building-industry-section-2-items">
            {items.map((item) => (
              <div className="building-industry-section-2-item">
                <img src={item.image} alt="" />
                <h3>{item.title}</h3>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default BuildingInsdustry;
