import { Link, useNavigate } from 'react-router-dom';
import './preRegistration.css';
import { TextField } from '@mui/material';
import grilleDesPrix from '../../../assets/images/prix_des_formations.svg';
import ficheDePreInscriptionEnFrancais from '../../../assets/files/fiche_de_pre-inscription_en_francais.docx';
import ficheDePreInscriptionEnAnglais from '../../../assets/files/fiche_de_pre-inscription_en_anglais.docx';

function PreRegistration() {
  let navigate = useNavigate();
  return (
    <div className="pre-registration">
      <section className="pre-registration-section-1">
        <div></div>
      </section>
      <div style={{ backgroundColor: '#FFF' }}>
        <div className="container">
          <section className="pre-registration-section-2">
            <h1>
              CENTRE DE FORMATION PROFESSIONNELLE ET DE LA PROMOTION DE L'EMPLOI
              NJA'MOUN DU CAMEROUN (CEFPEN)
            </h1>
            <p>
              Arrêté N°000586/MINEFOP/SG/DFOP/SDGSF/CSACD/CBAC du 13/12/2023
            </p>
          </section>

          <section className="pre-registration-section-3">
            <div className="pre-registration-section-3-box-1">
              <h2 style={{ textAlign: 'center', marginBottom: 20 }}>
                Nos filières
              </h2>
              <p>INDUSTRIES DU BÂTIMENT ET HABITAT DURABLE</p>
              <ul>
                <li>Électricité et énergies renouvelables</li>
                <li>Construction des ouvrages métalliques</li>
                <li>Peinture bâtiment, staff et décoration intérieure</li>
                <li>Maçonnerie</li>
                <li>Plomberie -installation sanitaire</li>
                <li>Menuiserie Ebénististe</li>
                <li>Production des matériaux de construction</li>
                <li>Carrelage bâtiment</li>
              </ul>
              <p>INDUSTRIES CRÉATIVES, ARTISANALES ET TOURISMES</p>
              <ul>
                <li>Stylisme, modélisme et Couture</li>
                <li>Hôtellerie et Restauration</li>
                <li>Tissage, art du bronze, des pierres et du bois</li>
                <li>
                  Graphisme de production, publicité et montage audiovisuel
                </li>
                <li>Cinéma et production artistique et culturelle</li>
              </ul>
              <p>AGRO BUSINESS</p>
              <ul>
                <li>Production végétale Durable et Biologique</li>
                <li>Production animale Durable et Biologique</li>
                <li>Agro-industrie</li>
                <li>
                  Production du biogaz et de pavées écologiques et de charbon
                  écologique (bio char)
                </li>
              </ul>
              <p>
                INFORMATIQUE, NOUVELLES TECHNOLOGIES ET TECHNOLOGIES DU FUTUR
              </p>
              <ul>
                <li>Secrétariat comptable</li>
                <li>Maintenance et réseaux informatiques</li>
                <li>Développement d'applications</li>
                <li>Webmestre</li>
              </ul>
            </div>
            <div className="pre-registration-section-3-box-2">
              <h2 style={{ textAlign: 'center', marginBottom: 20 }}>
                Conditions d'accès
              </h2>
              <ol>
                <li>Pré-inscription</li>
                <li>Test psychotechnique et entretien d'orientation</li>
                <li>
                  <p>Dossier d'admission</p>
                  <ul>
                    <li>
                      Remplir la fiche d'inscription disponible au centre et sur
                      site
                    </li>
                    <li>Fiche d'orientation à remplir au centre nja'moun</li>
                    <li>Photocopie d'acte de naissance</li>
                    <li>
                      Photocopie de CNI / Carte Scolaire en cours de validité
                    </li>
                    <li>04 photos 4*4 en couleur</li>
                    <li>01 enveloppe A4 portant l'adresse du Candidat</li>
                  </ul>
                </li>
                <li>Frais d'inscription: 50 000 FCFA</li>
                <li>
                  Coût de la formation incluant fournitures, matériel de
                  formation et kit d'installation en fonction des filières
                </li>
              </ol>
            </div>
          </section>
          <section className="pre-registration-section-4">
            <h2 style={{ textAlign: 'center', marginBottom: 20 }}>
              Coût des formations
            </h2>
            <img src={grilleDesPrix} alt="" style={{ width: '100%' }} />
          </section>
          <section className="pre-registration-section-5">
            <p className="pre-registration-section-5-box-1-p-1">
              <span>NB</span> Les fiches d'inscription et d'orientation sont
              disponibles au secrétariat du centre nja'moun
            </p>
            <a
              className="pre-registration-section-5-box-1-a-1"
              href="https://docs.google.com/forms/d/e/1FAIpQLScqknmJK4dYL4vBq1oeXyyke-5t_zkr5_l9qmt0tRHwEEYjpQ/viewform"
            >
              Se pré-inscrire
            </a>
            <p style={{ textAlign: 'center' }}>ou</p>
            <a
              className="pre-registration-link"
              href={ficheDePreInscriptionEnFrancais}
              download="fiche_de_pre-inscription_en_francais"
            >
              Télécharger la fiche de pré-inscription en français
            </a>
            <a
              className="pre-registration-link"
              href={ficheDePreInscriptionEnAnglais}
              download="fiche_de_pre-inscription_en_anglais"
            >
              Télécharger la fiche de pré-inscription en anglais
            </a>
          </section>
        </div>
      </div>
    </div>
  );
}

export default PreRegistration;
