import './candidature_apprenant.css';
import ficheDePreInscriptionEnFrancais from '../../../assets/files/fiche_de_pre-inscription_en_francais.docx';
import ficheDePreInscriptionEnAnglais from '../../../assets/files/fiche_de_pre-inscription_en_anglais.docx';

function CandidateurApprenant() {
  return (
    <div className="candidature-apprenant">
      <section className="candidature-apprenant-section-1">
        <div></div>
      </section>
      <div style={{ backgroundColor: '#FFF' }}>
        <div className="candidature-apprenant-container">
          <h1>
            APPEL À CANDIDATURE POUR LES PRE-INSCRIPTIONS DES APPRENANTS POUR LE
            COMPTE DE L'ANNÉE DE FORMATION 2024-2025
          </h1>
          <p>
            Le Centre de Formation Professionnelle et de Promotion de l'Emploi
            Nja'moun du Cameroun (CEFPEN) entend offrir des enseignements et des
            formations techniques et professionnels répondant aux besoins de
            main d'œuvre de l'économie, tant quantitativement que
            qualitativement. Pour atteindre cette vision, qui s'inscrit dans le
            domaine central de l'entrepreneuriat et de l'emploi, le CEFPEN
            envisage le recrutement des apprenants pour l'année académique
            2024-2025, l'objet de cet appel à candidature pour la participation
            aux tests psychotechniques.
          </p>
          <p>
            Les secteurs de formation ciblés sont :{' '}
            <strong>
              les Industries du Bâtiment et Habitat Durable; Technologies du
              futur et innovation; l'Agro-business et les Industries créatives,
              artisanal et tourisme
            </strong>
          </p>
          <p>
            Pour plus de précision sur les spécialités pour chaque filière,
            consulter attentivement le tableau ci-dessous :
          </p>
          <table>
            <tr>
              <th>FILIERES</th>
              <th>SPECIALITE</th>
            </tr>
            <tr>
              <td rowSpan={8} className="candidature-apprenant-td-left-1">
                Industries du Bâtiment et Habitat Durable
              </td>
              <td className="candidature-apprenant-td-right-1">
                Électricité et énergies renouvelable
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-1">
                Carrelage et staff bâtiment
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-1">Maçonnerie</td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-1">
                Construction ouvrage métallique (soudure)
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-1">
                Plomberie et installation sanitaire
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-1">
                Menuiserie ébéniste
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-1">
                Peinture, staff et décoration intérieure
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-1">
                Production des matériaux
              </td>
            </tr>
            <tr>
              <td rowSpan={12} className="candidature-apprenant-td-left-2">
                Technologies du futur et innovation
              </td>
              <td className="candidature-apprenant-td-right-2">
                Assistant de direction option secrétariat bureautique
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-2">
                Assistant de direction option Comptabilité
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-2">
                Assistant de direction option Comptabilité et gestion des
                organisations
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-2">
                Assistant de direction option Approvisionnement inventaire et
                gestion de stock - logistique (gestion des inventaires et des
                immobilisations)
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-2">
                Assistant de direction option Ressources Humaines
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-2">
                Assistant de direction option Passation des marchés et
                acquisitions
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-2">
                Maintenance réseau informatique
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-2">
                E-commerce : Infographie, graphisme et marketing digital
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-2">Webmaster</td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-2">
                Intelligence artificielle et applications digitales
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-2">
                Montage audiovisuel
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-2">
                Télématique : installation des camera/vidéo, tracking system/GPS
              </td>
            </tr>
            <tr>
              <td rowSpan={4} className="candidature-apprenant-td-left-1">
                Industries créatives, artisanal et tourisme
              </td>
              <td className="candidature-apprenant-td-right-1">
                Styliste modéliste / Couture
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-1">
                Hôtellerie et Restauration
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-1">
                Cinéma et production artistique et culturelle
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-1">
                Tissage art de bronze, de pierre et du bois.
              </td>
            </tr>
            <tr>
              <td rowSpan={3} className="candidature-apprenant-td-left-2">
                Agro business
              </td>
              <td className="candidature-apprenant-td-right-2">
                Production animale Durable/Biologique
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-2">
                Productions Végétales Durable/ Biologiques
              </td>
            </tr>
            <tr>
              <td className="candidature-apprenant-td-right-2">
                Production du biogaz et de pavées écologiques et de charbon
                écologique (bio char)
              </td>
            </tr>
          </table>
          <p>
            Tous ceux qui sont intéressés par ces offres de formation du CEFPEN,
            sont priés de cliquer sur le lien suivant :
          </p>
          <p>
            <a
              style={{ fontWeight: 'bold' }}
              href="https://docs.google.com/forms/d/e/1FAIpQLScqknmJK4dYL4vBq1oeXyyke-5t_zkr5_l9qmt0tRHwEEYjpQ/viewform?usp=pp_url"
            >
              Se pré-inscrire
            </a>{' '}
            pour vous préinscrire en ligne ou votre enfant.
          </p>
          <p>
            La fiche de préinscription est également disponible en imprimée au
            secrétariat du Centre Nja'Moun sis après la gare routière de Koutaba
            en allant vers Foumban plus précisément à l'immeuble Fleur de lys.
          </p>
          <p>
            <strong>N.B.</strong> Le recrutement est ouvert aux camerounais et
            étrangers des deux sexes. Agés de 14 ans au moins. Les candidats
            seront soumis à un test psychotechnique et à l'issu de ce test, les
            candidats retenus seront informés sur les modalités d'inscription au
            CEFPEN.
          </p>
          <p>
            Les candidatures féminines sont encouragées et le délai de
            soumission des candidatures est fixé au 31 Juillet 2024 à 00h00.
            Pour toutes les informations complémentaires, veuillez contacter les{' '}
            <strong>694819392 / 656762752 / 679061386</strong>.
          </p>

          <a
            className="candidature-apprenant-file-download-link"
            href={ficheDePreInscriptionEnFrancais}
            download="fiche_de_pre-inscription_en_francais"
          >
            Télécharger la fiche de pré-inscription en français
          </a>
          <a
            className="candidature-apprenant-file-download-link"
            href={ficheDePreInscriptionEnAnglais}
            download="fiche_de_pre-inscription_en_anglais"
          >
            Télécharger la fiche de pré-inscription en anglais
          </a>
        </div>
      </div>
    </div>
  );
}

export default CandidateurApprenant;
